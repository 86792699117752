<!-- 3D个位相加走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>福彩 3 D 个 位 相 加 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a">期号</th>
                        <th rowspan="2" class="ranks_a1">开奖</th>
                        <th colspan="10" class="ranks_b">3 D 个 位 走 势 图</th>
                        <th colspan="10" class="ranks_b">二 个 尾 数 相 加 走 势 图</th>
                        <th colspan="3" class="ranks_b">二尾012路</th>
                        <th colspan="3" class="ranks_b">二尾147路</th>
                        <th colspan="3" class="ranks_b">二尾258路</th>
                        <th colspan="2" class="ranks_b">奇 偶</th>
                        <th colspan="2" class="ranks_b">大 小</th>
                        <th colspan="2" class="ranks_b">质 合</th>
                        <th colspan="10" class="ranks_b">三 个 尾 数 相 加 走 势 图</th>
                        <th colspan="3" class="ranks_b">三尾012路</th>
                        <th colspan="3" class="ranks_b">三尾147路</th>
                        <th colspan="3" class="ranks_b">三尾258路</th>
                        <th colspan="2" class="ranks_b">奇 偶</th>
                        <th colspan="2" class="ranks_b">大 小</th>
                        <th colspan="2" class="ranks_b">质 合</th>


                    </tr>
                    <tr>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>

                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">奇</th>
                        <th class="ranks_c">偶</th>
                        <th class="ranks_c">大</th>
                        <th class="ranks_c">小</th>
                        <th class="ranks_c">质</th>
                        <th class="ranks_c">合</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in fc3d" :key='x'>
                        <td>{{item2.expect}}</td>
                        <td>
                            <div>
                                {{item2.red[0] }} {{item2.red[1] }} {{item2.red[2] }}
                            </div>
                        </td>
                        <!-- 和值 走势图 -->
                        <td class="red2" v-for="(item ,i) in red" :key='i'>
                            <div :class=" item ==gs1[x] ? 'q-lan' : '' ">
                                {{ item ==gs1[x] ? item : '' }}
                            </div>
                        </td>
                        <!-- 二相加 -->
                        <td class="red1" v-for="(item ,i) in red" :key='i'>
                            <div :class=" item ==red2[x] ? 'q-red' : '' ">
                                {{ item ==red2[x] ? item : '' }}
                            </div>
                        </td>
                        <!-- 012路 -->
                        <td class="red2" v-for="(item, i) in lye" :key="i">
                            <div :class="item == red2[x]%3 ? 'q-lan' : ''" >
                                {{ item == red2[x]%3 ? item : '' }}
                            </div>
                        </td>
                        <!-- 147路 -->
                        <td class="red1" v-for="(item, i) in ysq" :key="i">
                            <div :class=" item==(([1,5,6,9].indexOf(red2[x])==-1 ? '' : 1)||([0,2,4].indexOf(red2[x])==-1 ? '' : 4) || ([3,7,8].indexOf(red2[x])==-1 ? '' : 7) )? 'q-red' : ''">
                                {{ item==(([1,5,6,9].indexOf(red2[x])==-1 ? '' : 1)||([0,2,4].indexOf(red2[x])==-1 ? '' : 4) || ([3,7,8].indexOf(red2[x])==-1 ? '' : 7) )? item : ''}}
                            </div>
                        </td>
                        <!-- 258路 -->
                        <td class="red2" v-for="(item, i) in ewb" :key="i">
                            <div :class="item==(([2,7,9].indexOf(red2[x])==-1 ? '' : 2)||([0,3,5].indexOf(red2[x])==-1 ? '' : 5) || ([1,4,6,8].indexOf(red2[x])==-1 ? '' : 8) )? 'q-lan' : ''" >
                                {{ item==(([2,7,9].indexOf(red2[x])==-1 ? '' : 2)||([0,3,5].indexOf(red2[x])==-1 ? '' : 5) || ([1,4,6,8].indexOf(red2[x])==-1 ? '' : 8) )? item : ''}}
                            </div>
                        </td>
                        <!-- 奇偶 -->
                        <td class="red1" v-for="(item, i) in jo" :key="i">
                            <div :class="item ==(red2[x]%2==0 ? '偶': '奇')  ? 'q-red' : ''" >
                                {{ item ==(red2[x]%2==0 ? '偶': '奇')  ? item : '' }}
                            </div>
                        </td>
                        <!-- 大小 -->
                        <td class="red2" v-for="(item, i) in dx" :key="i">
                            <div :class="item ==(red2[x]>4 ? '大': '小')  ? 'q-lan' : ''">
                                {{ item ==(red2[x]>4 ? '大': '小')  ? item : '' }}
                            </div>
                        </td>
                        <!-- 质合 -->
                        <td class="red1" v-for="(item, i) in zh" :key="i">
                            <div :class="item ==([1,2,3,5,7].indexOf(red2[x])==-1 ? '合' : '质')  ? 'q-red' : ''" >
                                {{ item ==([1,2,3,5,7].indexOf(red2[x])==-1 ? '合' : '质')  ? item : '' }}
                            </div>
                        </td>
                        <!-- 三相加 -->
                        <td class="red2" v-for="(item ,i) in red" :key='i'>
                            <div :class=" item ==red3[x] ? 'q-lan' : '' ">
                                {{ item ==red3[x] ? item : '' }}
                            </div>
                        </td>
                         <!-- 012路 -->
                         <td class="red1" v-for="(item, i) in lye" :key="i">
                            <div :class="item == red3[x]%3 ? 'q-red' : ''" >
                                {{ item == red3[x]%3 ? item : '' }}
                            </div>
                        </td>
                        <!-- 147路 -->
                        <td class="red2" v-for="(item, i) in ysq" :key="i">
                            <div :class=" item==(([1,5,6,9].indexOf(red3[x])==-1 ? '' : 1)||([0,2,4].indexOf(red3[x])==-1 ? '' : 4) || ([3,7,8].indexOf(red3[x])==-1 ? '' : 7) )? 'q-lan' : ''">
                                {{ item==(([1,5,6,9].indexOf(red3[x])==-1 ? '' : 1)||([0,2,4].indexOf(red3[x])==-1 ? '' : 4) || ([3,7,8].indexOf(red3[x])==-1 ? '' : 7) )? item : ''}}
                            </div>
                        </td>
                        <!-- 258路 -->
                        <td class="red1" v-for="(item, i) in ewb" :key="i">
                            <div :class="item==(([2,7,9].indexOf(red3[x])==-1 ? '' : 2)||([0,3,5].indexOf(red3[x])==-1 ? '' : 5) || ([1,4,6,8].indexOf(red3[x])==-1 ? '' : 8) )? 'q-red' : ''" >
                                {{ item==(([2,7,9].indexOf(red3[x])==-1 ? '' : 2)||([0,3,5].indexOf(red3[x])==-1 ? '' : 5) || ([1,4,6,8].indexOf(red3[x])==-1 ? '' : 8) )? item : ''}}
                            </div>
                        </td>
                        <!-- 奇偶 -->
                        <td class="red2" v-for="(item, i) in jo" :key="i">
                            <div :class="item ==(red3[x]%2==0 ? '偶': '奇')  ? 'q-lan' : ''" >
                                {{ item ==(red3[x]%2==0 ? '偶': '奇')  ? item : '' }}
                            </div>
                        </td>
                        <!-- 大小 -->
                        <td class="red1" v-for="(item, i) in dx" :key="i">
                            <div :class="item ==(red3[x]>4 ? '大': '小')  ? 'q-red' : ''">
                                {{ item ==(red3[x]>4 ? '大': '小')  ? item : '' }}
                            </div>
                        </td>
                        <!-- 质合 -->
                        <td class="red2" v-for="(item, i) in zh" :key="i">
                            <div :class="item ==([1,2,3,5,7].indexOf(red3[x])==-1 ? '合' : '质')  ? 'q-lan' : ''" >
                                {{ item ==([1,2,3,5,7].indexOf(red3[x])==-1 ? '合' : '质')  ? item : '' }}
                            </div>
                        </td>

                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 61" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 61" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>待开奖</td>
                        <td v-for="(x , i) in 61" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
     
    </div>
</template>

<script>
     import mitt from '@/js/mitt.js'
  
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-tail',
        components: {
        
        },
        data() { // 数据源
            return {
                red: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                red0: [],
                red2: [0],
                red3: [0, 0],
                lye: [0, 1, 2],
                lye1: [],
                ysq: [1, 4, 7],
                ysq1: [],
                ewb: [2, 5, 8],
                ewb1: [],
                jo: ["奇", "偶"],
                jo1: [],
                dx: ["大", "小"],
                dx1: [],
                zh: ["质", "合"],
                zh1: [],
                zf: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                gs1: [],
                wx: ["金", "木", "水", "火", "土"],
                wx1: [],
            }
        },
        mounted() {
            //this.mred()
            this.mgs()
            this.mgs2()
            this.mgs3()
            this.mlye()
            this.mysq()
            this.mewb()
            this.mjo()
            this.mdx()
            this.mzh()
            this.find()
        },
        computed: {
            fc3d() {
                return this.$store.state.fc3d;
            },
        },
        methods: { // 函数体部分，js的主要逻辑控制  
            find() {
                document.documentElement.scrollTop = 0;
                if(this.fc3d==''){
                    mitt.emit('fc3d');   
                }
            },
            mgs() {  //百位
                this.gs1=[]
                for (var i = 0; i < this.fc3d.length; i++) {
                    var xx = new Array();
                    xx = this.fc3d[i].red[2] 
                    this.gs1.push(Math.round(xx % 10));
                }
            },

            mgs2() {//二相加
                this.red2=[0]
                for (var i = 0; i < this.gs1.length - 1; i++) {
                    var xx = new Array();
                    xx = (this.gs1[i] + this.gs1[i + 1]) % 10
                    this.red2.push(Math.round(xx % 10));
                    // console.log(this.red2)
                }
            },

            mgs3() {//三相加
                this.red3=[0,0]
                for (var i = 0; i < this.gs1.length - 2; i++) {
                    var xx = new Array();
                    xx = (this.gs1[i] + this.gs1[i + 1] + this.gs1[i + 2]) % 10
                    this.red3.push(Math.round(xx % 10));
                    // console.log(this.red2)
                }
            },


            mlye() { //012路
                this.lye1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (xx.indexOf(this.red0[i][j] % 3) == -1) {
                            xx.push(this.red0[i][j] % 3);
                        }
                    }
                    this.lye1.push(xx);
                }
            },

            mysq() {  //147路
                this.ysq1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (
                            this.red0[i][j] == 1 ||
                            this.red0[i][j] == 5 ||
                            this.red0[i][j] == 6 ||
                            this.red0[i][j] == 9
                        ) {
                            if (xx.indexOf(1) == -1) {
                                xx.push(1);
                            }
                        } else if (
                            this.red0[i][j] == 0 ||
                            this.red0[i][j] == 2 ||
                            this.red0[i][j] == 4
                        ) {
                            if (xx.indexOf(4) == -1) {
                                xx.push(4);
                            }
                        } else if (
                            this.red0[i][j] == 3 ||
                            this.red0[i][j] == 7 ||
                            this.red0[i][j] == 8
                        ) {
                            if (xx.indexOf(7) == -1) {
                                xx.push(7);
                            }
                        }
                    }
                    this.ysq1.push(xx);
                }
            },

            mewb() {  //258路
                this.ewb1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (
                            this.red0[i][j] == 2 ||
                            this.red0[i][j] == 7 ||
                            this.red0[i][j] == 9
                        ) {
                            if (xx.indexOf(2) == -1) {
                                xx.push(2);
                            }
                        } else if (
                            this.red0[i][j] == 0 ||
                            this.red0[i][j] == 3 ||
                            this.red0[i][j] == 5
                        ) {
                            if (xx.indexOf(5) == -1) {
                                xx.push(5);
                            }
                        } else if (
                            this.red0[i][j] == 1 ||
                            this.red0[i][j] == 4 ||
                            this.red0[i][j] == 6 ||
                            this.red0[i][j] == 8
                        ) {
                            if (xx.indexOf(8) == -1) {
                                xx.push(8);
                            }
                        }
                    }
                    this.ewb1.push(xx);
                }
            },

            mjo() {  //奇偶
                this.jo1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (this.red0[i][j] % 2 == 0) {
                            if (xx.indexOf("偶") == -1) {
                                xx.push("偶");
                            }
                        } else {
                            if (xx.indexOf("奇") == -1) {
                                xx.push("奇");
                            }
                        }
                    }
                    this.jo1.push(xx);
                }
            },

            mdx() {  //大小
                this.dx1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if (this.red0[i][j] < 5) {
                            if (xx.indexOf("小") == -1) {
                                xx.push("小");
                            }
                        } else {
                            if (xx.indexOf("大") == -1) {
                                xx.push("大");
                            }
                        }
                    }
                    this.dx1.push(xx);
                }
            },

            mzh() {  //质合
                this.zh1=[]
                for (var i = 0; i < this.red0.length; i++) {
                    var xx = new Array();
                    for (var j = 0; j < this.red0[i].length; j++) {
                        if ([1, 2, 3, 5, 7].indexOf(this.red0[i][j]) == -1) {
                            if (xx.indexOf("合") == -1) {
                                xx.push("合");
                            }
                        } else {
                            if (xx.indexOf("质") == -1) {
                                xx.push("质");
                            }
                        }
                    }
                    this.zh1.push(xx);
                }
            },

         
        },



        props: {

        },
        watch: {
            fc3d() {
                this.mgs()
                this.mgs2()
                this.mgs3()
                this.mlye()
                this.mysq()
                this.mewb()
                this.mjo()
                this.mdx()
                this.mzh()
            }
        }

    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    /* .ranks_a {
        width: 60px;
    } */


    .ranks_a1 {
        width: 40px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .red2 {

        background-color: rgb(224, 191, 191);
    }

    tbody .red {
        background-color: red;
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }

    tbody .lan {
        background-color: blue;
        color: #fff;
        border-radius: 15px;
    }

    .red1 {
        background-color: #f8fcb2;
    }

    .red3 {
        background-color: rgb(9, 74, 112);
        border-radius: 15px;
        color: #fff;
    }

    .red4 {
        background-color: rgb(178, 10, 194);
        border-radius: 15px;
        color: #fff;
    }

    .red5 {
        background-color: #b79939;
        border-radius: 15px;
        color: #fff;
    }

    .red6 {
        background-color: #7fb1bd;
        border-radius: 15px;
        color: #fff;
    }



    .b-red {
        background-color: rgb(229, 243, 235);
    }

    .b-lan {
        background-color: #f8fcb2;
    }


    .q-red {
        background-color: rgb(87, 28, 114);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: #548ce0;
        border-radius: 15px;
        color: #fff;
    }
</style>